* {
  font-family: "VT323";
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: none;
  overflow: hidden;
}

/* p, h1, h2, a {
  overflow: auto;
} */

.container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
}

.net-cursor {
  position: absolute;
  left: -100px;
  top: -100px;
  pointer-events: none;
  z-index: 5;
  transform: translateX(-50%) translateY(-50%);
  width: 5vw;
  overflow: hidden;
}

.bubble {
  position: absolute;
  transform: translateX(-95%);
  z-index: 4;
  width: 2vw;
  left: -100px;
  right: -100px;
  pointer-events: none;
}

.tank {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.tank-dark {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: black;
  opacity: 0.2;
}

.fish {
  width: 10vw;
  position: absolute;
  cursor: pointer;
  background-image: radial-gradient(
    rgba(255, 255, 0, 1),
    rgba(255, 255, 0, 0.01),
    rgba(0, 0, 0, 0)
  );
  border-radius: 10%;
  overflow: hidden;
}

.fish-small {
  width: 6vw;
  position: absolute;
  cursor: pointer;
  background-image: radial-gradient(
    rgba(255, 255, 0, 1),
    rgba(255, 255, 0, 0.01),
    rgba(0, 0, 0, 0)
  );
  border-radius: 10%;
  overflow: hidden;
}

.fish-big {
  width: 15vw;
  position: absolute;
  cursor: pointer;
  background-image: radial-gradient(
    rgba(255, 255, 0, 1),
    rgba(255, 255, 0, 0.01),
    rgba(0, 0, 0, 0)
  );
  border-radius: 10%;
}

.fish-takes-space {
  width: 10vw;
}

.modal-dark {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  display: none;
}

.modal {
  height: calc(40vw - 50px);
  width: calc(80% - 50px);
  max-width:95%;
  max-height:95%;
  background-color: #eeeeee;
  border-radius: 50px;
  padding: 25px;
  overflow-y: auto;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  position: relative;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  opacity: 0.3;
}

.close-modal {
  text-decoration: underline;
  color: dodgerblue;
  cursor: pointer;
}

.fadeModal {
  animation: fadeModal 1s ease-in-out;
}

@keyframes fadeModal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showModal {
  animation: showModal 1s ease-in-out;
}

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.left-controls {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%) scaleX(-1);
  height: 20px;
  cursor: pointer;
}

.right-controls {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  height: 20px;
  cursor: pointer;
}

.project-image {
  width: 100%;
  border-radius: 20px;
}

.text-container {
  width: calc((100% - 50px) / 2);
  position: relative;
  overflow-y: auto;
}

.text-container > p {
  overflow-y: auto;
}

.image-container {
  width: calc((100% - 50px) / 2);
  display: flex;
  align-items: center;
  position: relative;
}

.fadeOut {
  animation: fadeOut 0.5s ease-in-out;
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 950px){
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: block;
  }

  .modal-background {
    display: none;
  }

  .text-container {
    width: 100%;
  }

  .image-container {
    width: 100%;
    margin-top: 50px;
  }

  .project-image {
    position: static !important;
  }

  .net-cursor {
    display: none;
  }

  .bubble {
    display: none;
  }

  .fish {
    width: 15vh;
  }

  .fish-big {
    width: 20vh;
  }

  .fish-small {
    width: 10vh;
  }

  .tank {
    height: 100vh;
    width: 200vh;
  }
}